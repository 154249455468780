// export const HOST           = 'https://api.afterdoc.net'
export const HOST              = 'https://dev.api.afterdoc.net'
// export const HOST              = 'http://192.168.1.14:8080'
// export const HOST              = 'http://192.168.1.15:8080'

// export const AL_HOST           = 'https://almighty.si.afterdoc.net'
export const AL_HOST            = 'https://dev.almighty.si.afterdoc.net'


export const SD_HOST            = 'https://reservation.api.receipt.smartdoctor.systems'

export const URL_ADMIN          = HOST + '/v3/admin'


export const HOSPITAL           = URL_ADMIN + '/hospitals'
export const HOSPITAL_COUNT     = HOSPITAL + '/count'
export const HOSPITAL_FIND      = HOSPITAL + '/find'
export const HOSPITAL_FIND_ONE  = HOSPITAL + '/find/one'
export const HOSPITAL_CREATE    = HOSPITAL + '/create'
export const HOSPITAL_UPDATE    = HOSPITAL + '/update'

export const HOSPITAL_ACCOUNT           = URL_ADMIN + '/hospitalaccount'
export const HOSPITAL_ACCOUNT_FIND      = HOSPITAL_ACCOUNT + '/find'
export const HOSPITAL_ACCOUNT_COUNT     = HOSPITAL_ACCOUNT + '/count'
export const HOSPITAL_ACCOUNT_CREATE    = HOSPITAL_ACCOUNT + '/create'
export const HOSPITAL_ACCOUNT_UPDATE    = HOSPITAL_ACCOUNT + '/update'
export const HOSPITAL_ACCOUNT_REMOVE    = HOSPITAL_ACCOUNT + '/remove'

export const SERVICE_SETTING            = URL_ADMIN + '/servicesetting'
export const SERVICE_SETTING_COUNT      = SERVICE_SETTING + '/count'
export const SERVICE_SETTING_FIND       = SERVICE_SETTING + '/find'
export const SERVICE_SETTING_FIND_ONE   = SERVICE_SETTING + '/find/one'
export const SERVICE_SETTING_CREATE     = SERVICE_SETTING + '/create'
export const SERVICE_SETTING_UPDATE     = SERVICE_SETTING + '/update'
export const SERVICE_SETTING_REMOVE     = SERVICE_SETTING + '/remove'
export const SERVICE_SETTING_ACCOUNT_COUNT_CHANGE       = SERVICE_SETTING + '/account/update'
export const SERVICE_SETTING_DEFAULT_COUNSELOR_UPDATE   = SERVICE_SETTING + '/defaultcounselor/update'

export const ACCOUNT_SETTING_HISTORY        = URL_ADMIN + '/hospitalaccount/history'
export const ACCOUNT_SETTING_HISTORY_COUNT  = ACCOUNT_SETTING_HISTORY + '/count'
export const ACCOUNT_SETTING_HISTORY_FIND   = ACCOUNT_SETTING_HISTORY + '/find'

export const ANSWERINGCHAT_SETTING          = URL_ADMIN + '/answeringchatsetting'
export const ANSWERINGCHAT_SETTING_CREATE   = ANSWERINGCHAT_SETTING + '/create'
export const ANSWERINGCHAT_SETTING_UPDATE   = ANSWERINGCHAT_SETTING + '/update'
export const ANSWERINGCHAT_SETTING_FIND_ONE = ANSWERINGCHAT_SETTING + '/find/one'

export const HEALTH_CONTENT                 = URL_ADMIN + '/healthcontent'
export const HEALTH_CONTENT_FIND            = HEALTH_CONTENT + '/find'

export const HEALTH_CONTENT_CATEGORY        = HEALTH_CONTENT + '/category'
export const HEALTH_CONTENT_CATEGORY_FIND   = HEALTH_CONTENT_CATEGORY + '/find'


export const NURSE              = URL_ADMIN + '/user/nurse'
export const NURSE_FIND_SIMPLE  = NURSE + '/find'

export const VIDEO_CONTENT                      = URL_ADMIN + '/videoContent'
export const VIDEO_CONTENT_CATEGORY             = VIDEO_CONTENT + '/category'
export const VIDEO_CONTENT_CATEGORY_FIND        = VIDEO_CONTENT_CATEGORY + '/find'
export const VIDEO_CONTENT_CATEGORY_FIND_ONE    = VIDEO_CONTENT_CATEGORY_FIND + '/one'

export const INTEGRATION_SETTING                = URL_ADMIN + '/integrationsetting'
export const INTEGRATION_SETTING_FIND           = INTEGRATION_SETTING + '/find'
export const INTEGRATION_SETTING_FIND_ONE       = INTEGRATION_SETTING_FIND + '/one'
export const INTEGRATION_SETTING_UPSERT         = INTEGRATION_SETTING + '/upsert'

export const TREATMENT_CATEGORY                 = URL_ADMIN + '/treatmentcategory'
export const TREATMENT_CATEGORY_COUNT           = TREATMENT_CATEGORY + '/count'
export const TREATMENT_CATEGORY_FIND            = TREATMENT_CATEGORY + '/find'
export const TREATMENT_CATEGORY_FIND_ONE        = TREATMENT_CATEGORY + '/find/one'
export const TREATMENT_CATEGORY_CREATE          = TREATMENT_CATEGORY + '/create'
export const TREATMENT_CATEGORY_UPDATE          = TREATMENT_CATEGORY + '/update'
export const TREATMENT_CATEGORY_REMOVE          = TREATMENT_CATEGORY + '/remove'

export const FILE                               = URL_ADMIN + '/file'
export const Country                            = URL_ADMIN + '/country'

export const ADMINAUTHORIZATION                 = URL_ADMIN + '/adminauthorizationtype'
export const ADMINAUTHORIZATION_FIND_ONE        = ADMINAUTHORIZATION + '/one'
export const ADMINUSER                          = URL_ADMIN + '/adminuser'
export const ADMINUSER_COUNT                    = ADMINUSER + '/count'
export const ADMINUSER_FIND_ONE                 = ADMINUSER + '/one'


export const KAKAOLINKAGE                       = URL_ADMIN + '/kakaolinkagehistory'
export const KAKAOLINKAGE_ONE                   = KAKAOLINKAGE + '/one'
export const KAKAOLINKAGE_COUNT                 = KAKAOLINKAGE + '/count'
export const KAKAOLINKAGE_DETAIL                = KAKAOLINKAGE + '/detail'
export const KAKAOLINKAGE_UPDATE                = KAKAOLINKAGE + '/update'
export const KAKAOLINKAGE_CLEAR                 = KAKAOLINKAGE + '/clear'


export const PUBLIC                             = HOST + '/v2/public'
export const THIRDPARTY_REGIST_KAKAO_CATEGORIES = PUBLIC + '/regist/kakao/category'

export const KAKAO_TEMPLATE                     = URL_ADMIN + '/kakaotemplate'
export const KAKAO_TEMPLATE_GROUPS              = KAKAO_TEMPLATE + '/kakaogroups'
export const KAKAO_TEMPLATE_PROFILE             = KAKAO_TEMPLATE + '/kakaoprofile'
export const KAKAO_TEMPLATE_ALL_PROFILES        = KAKAO_TEMPLATE + '/kakaoprofiles/all'
export const KAKAO_TEMPLATE_CATEGORIES          = KAKAO_TEMPLATE + '/kakaocategory'
export const KAKAO_TEMPLATE_CREATE              = KAKAO_TEMPLATE + '/create'
export const KAKAO_TEMPLATE_DETAIL              = KAKAO_TEMPLATE + '/detail'
export const KAKAO_TEMPLATE_REQUEST             = KAKAO_TEMPLATE + '/request'
export const KAKAO_TEMPLATE_REQUEST_CANCEL      = KAKAO_TEMPLATE + '/request/cancel'
export const KAKAO_TEMPLATE_REQUEST_STOP        = KAKAO_TEMPLATE + '/stop'
export const KAKAO_TEMPLATE_REQUEST_REUSE       = KAKAO_TEMPLATE + '/reuse'
export const KAKAO_TEMPLATE_REQUEST_RELEASE     = KAKAO_TEMPLATE + '/release'
export const KAKAO_TEMPLATE_REQUEST_CANCEL_APPROVAL = KAKAO_TEMPLATE + '/cancelapproval'

export const AUTOMATION                         = URL_ADMIN + '/automation'
export const AUTOMATION_COUNT                   = AUTOMATION + '/count'

export const AUTOMATION_HOSPITAL                = AUTOMATION + '/hospital'
export const AUTOMATION_HOSPITAL_VIDEOCONTENT   = AUTOMATION_HOSPITAL + '/videocontent'
export const AUTOMATION_HOSPITAL_AFTERCARE      = AUTOMATION_HOSPITAL + '/homecare'
export const AUTOMATION_HOSPITAL_EVENT          = AUTOMATION_HOSPITAL + '/event'